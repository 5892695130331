import React, { useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  get,
  map,
  keys,
  find,
  reject,
  isEqual,
  filter,
} from '../../lib/nodash';
import { useQuery } from '@apollo/client';
import { Box, Heading, Anchor, Text } from 'grommet';

import { shopifyClient as client } from '../../gatsby-theme-apollo/client';
import calculateVariantsForGallons from '../../lib/calculations/calculateVariantsForGallons';
import encodeGid from '../../lib/encodeGid';
import GallonProductBreakdownItem from './GallonProductBreakdownItem';

import { GET_FULL_PRODUCT } from '../../queries/productQueries';
import {
  excludeVariants,
  getExcludedVariantsFromProductResponse,
} from '../../lib/product';
import deNodify from '../../lib/deNodify';

const GallonProductBreakdown = ({
  gallons,
  productId,
  onChange,
  onSelect,
  isSelected,
  onNotifyRestock,
  label,
}) => {
  const { data } = useQuery(GET_FULL_PRODUCT, {
    variables: { productId: encodeGid(productId) },
    client,
  });
  const product = get('node', data);
  const variants = useMemo(
    () =>
      excludeVariants(
        get('variants', product) ? deNodify(get('variants', product)) : [],
        getExcludedVariantsFromProductResponse(data)
      ),
    [productId, data]
  );

  const varMap = useMemo(
    () => calculateVariantsForGallons(variants, gallons),
    [variants, gallons]
  );

  useEffect(() => {
    if (onChange) {
      onChange(varMap, variants);
    }
  }, [variants, gallons, varMap]);

  const suggested = reject(
    (x) => isEqual(0, get('qty', x)) || isEqual(null, get('qty', x)),
    map(
      (x) => ({ variant: find({ id: x }, variants), qty: varMap[x] }),
      keys(varMap)
    )
  );

  const outOfStock = filter(
    (x) => isEqual(null, x.qty),
    map(
      (x) => ({ variant: find({ id: x }, variants), qty: varMap[x] }),
      keys(varMap)
    )
  );

  return (
    <Box>
      <Box pad="small" gap="medium">
        {suggested.map((x) => (
          <GallonProductBreakdownItem
            key={get('variant.id', x)}
            variant={get('variant', x)}
            quantity={get('qty', x)}
            product={product}
            onSelect={onSelect}
            isSelected={isSelected}
            label={label}
          />
        ))}
      </Box>
      {outOfStock.length > 0 && (
        <Box gap="small" margin={{ vertical: 'medium' }}>
          <Heading level={5} margin="none">
            Currently Out of Stock:
          </Heading>
          {outOfStock.map((x) => (
            <Box
              key={x.variant.id}
              direction="row"
              wrap={true}
              gap="small"
              fill="horizontal"
            >
              <Box flex={true}>
                <Text>{get('variant.title', x)}</Text>
              </Box>
              <Box>
                <Anchor
                  size="small"
                  onClick={() => onNotifyRestock(get('variant', x), product)}
                  style={{ textDecoration: 'underline' }}
                >
                  Notify me
                </Anchor>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

GallonProductBreakdown.propTypes = {
  gallons: PropTypes.number,
  productId: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  onNotifyRestock: PropTypes.func,
  label: PropTypes.string,
};

export default memo(GallonProductBreakdown);
