import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Box } from 'grommet';
import { get, find, head } from '../../lib/nodash';

import FormPaintVariantSelector from './FormPaintVariantSelector';
import Loading from '../Loading';
import { shopifyClient as client } from '../../gatsby-theme-apollo/client';
import findImage from '../../lib/findImage';
import {
  isVariantHalfGal,
  isVariantPurchasable,
  findMetafieldValue,
  excludeVariants,
  getExcludedVariantsFromProductResponse,
} from '../../lib/product';
import deNodify from '../../lib/deNodify';
import encodeGid from '../../lib/encodeGid';
import { deactivateProductVariantSelector } from '../../state/product/productSlice';
import { setActiveModal } from '../../state/ui/uiSlice';
import {
  GET_FULL_PRODUCT_BY_VARIANT_ID,
  GET_FULL_PRODUCT_BY_HANDLE,
} from '../../queries/productQueries';

const PaintVariantSelector = ({
  variantId,
  altProducts,
  onCalc,
  handleGallonSelect,
  paintCollection,
}) => {
  const dispatch = useDispatch();
  const collectionMetafields = paintCollection?.metafields || [];
  const { data, loading } = useQuery(GET_FULL_PRODUCT_BY_VARIANT_ID, {
    variables: { variantId: encodeGid(variantId) },
    client,
  });
  const { data: primerData } = useQuery(GET_FULL_PRODUCT_BY_HANDLE, {
    variables: { productHandle: 'primer' },
    client,
  });
  const product = get('node.product', data);

  const variants = excludeVariants(
    get('variants', product) ? deNodify(get('variants', product)) : [],
    getExcludedVariantsFromProductResponse(data)
  );

  const halfGallonAvailable = isVariantPurchasable(
    find(isVariantHalfGal, deNodify(get('variants', product)))
  );

  const gridImageUrl = useMemo(
    () =>
      paintCollection
        ? findMetafieldValue('collection_image', collectionMetafields)?.[0]
            ?.original_src ||
          findMetafieldValue('collection_image', collectionMetafields)
            ?.originalSrc
        : findImage(get('images', product) || [], '_grid') ||
          findImage(get('images', product) || [], '_square'),
    [product]
  );

  const handleAdded = useCallback(
    () => dispatch(deactivateProductVariantSelector()),
    [dispatch, deactivateProductVariantSelector]
  );
  const handleNotifyRestock = useCallback(() => {
    dispatch(deactivateProductVariantSelector());
    dispatch(
      setActiveModal({
        type: 'component',
        value: 'product-restock-notification',
        meta: {
          variantId,
          variantTitle: get('node.title', data),
          productTitle: get('title', product),
          isSample: false,
        },
      })
    );
  });

  return (
    <Box className="paint-variant-selector" gap="small">
      {loading ? (
        <Box fill justify="center" align="center">
          <Loading size="large" />
        </Box>
      ) : (
        <FormPaintVariantSelector
          variant={get('node', data)}
          variants={variants}
          primer={get('productByHandle', primerData)}
          altProducts={altProducts}
          imageUrl={gridImageUrl}
          onCalc={onCalc}
          handleGallonSelect={handleGallonSelect}
          halfGallonAvailable={halfGallonAvailable}
          onAdded={handleAdded}
          onNotifyRestock={handleNotifyRestock}
        />
      )}
    </Box>
  );
};

PaintVariantSelector.propTypes = {
  variantId: PropTypes.string.isRequired,
  altProducts: PropTypes.array,
  onCalc: PropTypes.func,
  handleGallonSelect: PropTypes.func,
  paintCollectionId: PropTypes.string,
  paintCollection: PropTypes.object,
};

export default memo(PaintVariantSelector);
