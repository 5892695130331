import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Image, CheckBox } from 'grommet';
import { get } from '../../lib/nodash';
import styled from 'styled-components';
import formatCurrency from '../../lib/formatCurrency';

import VariantGallonsPerUnitText from './VariantGallonsPerUnitText';
import TextTitle from '../TextTitle';

const CheckboxContainer = styled(Box)`
  input {
    padding: 0;
  }
`;

const GallonProductBreakdownItem = ({
  variant,
  quantity,
  onSelect,
  isSelected,
  label,
}) => {
  return (
    <Box
      direction="row"
      gap="small"
      flex={true}
      justify="between"
      align="center"
    >
      <Box width="50px" height="50px">
        <Image fit="contain" src={get('image.thumb', variant)} />
      </Box>
      <Box>
        <TextTitle size="small">
          {quantity} <span style={{ textTransform: 'normal' }}>x</span>{' '}
          <VariantGallonsPerUnitText
            size="small"
            weight={400}
            variant={variant}
          />
          {label ? ` ${label}` : ''}
        </TextTitle>
      </Box>
      {onSelect ? (
        <CheckboxContainer>
          <CheckBox checked={isSelected} onChange={onSelect} />
        </CheckboxContainer>
      ) : (
        <Box>
          <Text>{formatCurrency(get('price.amount', variant))}</Text>
        </Box>
      )}
    </Box>
  );
};

GallonProductBreakdownItem.propTypes = {
  variant: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
};

export default memo(GallonProductBreakdownItem);
