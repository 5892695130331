import React, { useRef, useMemo, memo, useContext } from 'react';
import {
  get,
  map,
  reduce,
  find,
  filter,
  keys,
  isNil,
  isEqual,
  sortBy,
  groupBy,
  toLower,
  reverse,
  head,
  isFunction,
  every,
  values as _values,
  startCase,
  mapKeys,
  flatten,
} from '../../lib/nodash';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Heading,
  Image,
  Anchor,
  Text,
  Button,
  Stack,
  ThemeContext,
} from 'grommet';
import { FormDown } from 'grommet-icons';
import styled from 'styled-components';

import InfoToolTip from '../InfoToolTip';
import InputSelectWithDescription from '../Inputs/InputSelectWithDescription';
import GallonProductBreakdown from './GallonProductBreakdown';
import Loading from '../Loading';
import useMobile from '../useMobile';
import useAddToCart from '../Cart/useAddToCart';
import extractGid from '../../lib/extractGid';
import formatCurrency from '../../lib/formatCurrency';
import { isVariantHalfGal, findOneGalVariant } from '../../lib/product';
import { PAINT_ADDED } from '../../lib/analytics/segmentActions';
import { CABINET_DOOR } from '../../lib/productTypes';
import { track } from '../../lib/analytics';
import ImgixImage from '../ImgixImage';

const PRIMER_RECOMMENDATION_TAG = 'recommended:primer';

const SelectContainer = styled(Box)`
  font-size: ${(p) => (p.isMobile ? '1.4rem' : '1.6rem')};
  position: relative;
  button {
    border-bottom: 2px solid #000;
  }
  button[role='menuitem'] {
    padding: 0.3rem;
  }
  input {
    padding-left: 0;
    padding-right: 0;
  }
  select {
    text-transform: none;
    border: none;
    border-bottom: 2px solid #000;
    font-size: 23px;
    padding: 0.8rem 0.6rem;
    height: auto;
    line-height: 28px;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
  }
`;

const getDescriptionForFinish = (type) => {
  switch (type) {
    case 'Interior Standard':
      return 'Interior wall surfaces';
    case 'Interior Semi-Gloss':
      return 'Bathrooms, doors, trim';
    case 'Exterior Standard':
      return 'Exterior wall surfaces';
    case 'Exterior Semi-Gloss':
      return 'Exterior doors, trim';
    case 'Cabinet & Door':
      return 'Interior cabinets and doors';
    default:
  }
};

const FormPaintVariantSelector = ({
  variant,
  variants,
  altProducts,
  imageUrl,
  primer,
  onCalc,
  halfGallonAvailable = false,
  onAdded,
  onNotifyRestock,
}) => {
  const theme = useContext(ThemeContext);
  const { addToCart } = useAddToCart();
  const isMobile = useMobile();
  const scrollRef = useRef(null);
  const product = get('product', variant);
  const isPrimer = isEqual(
    'primer',
    toLower(get('productType', head(altProducts)))
  );

  const isInterior =
    toLower(get('productType', product)).indexOf('interior') > -1;

  const sorted = useMemo(
    () => sortBy([(x) => get('productType', x) || get('type', x)], altProducts),
    [altProducts]
  );

  const grouped = useMemo(
    () =>
      groupBy(
        (x) => head((get('productType', x) || get('type', x)).split(' ')),
        isInterior ? reverse(sorted) : sorted
      ),
    [sorted, isInterior]
  );

  const flattened = useMemo(
    () =>
      reduce(
        (mem, x) => {
          return [...mem, ...grouped[x]];
        },
        [],
        keys(grouped)
      ),
    [grouped]
  );

  const allVariants = useMemo(
    () => flatten(altProducts.map((x) => x.variants)),
    [altProducts]
  );

  const finishOptions = useMemo(
    () =>
      map((x) => {
        const id = get('shopifyId', x) ?? get('id', x);
        return {
          label: isPrimer
            ? startCase(toLower(get('title', x)))
            : get('type', x) || get('productType', x) || x,
          value: id || null,
          description: getDescriptionForFinish(
            get('type', x) || get('productType', x)
          ),
        };
      }, flattened),
    [flattened, getDescriptionForFinish]
  );

  const disabledProductOptions = map(
    (z) => finishOptions.indexOf(z),
    filter((x) => {
      return isNil(get('value', x));
    }, finishOptions)
  );

  return (
    <Box align="center" className="form-container" justify="center">
      <Box justify="center" align="center">
        <Heading
          align="center"
          textAlign="center"
          level={4}
          margin={{ top: 'xsmall' }}
        >
          {product?.title}
        </Heading>
        <Text
          textAlign="center"
          size="medium"
          dangerouslySetInnerHTML={{ __html: get('descriptionHtml', product) }}
        />
      </Box>
      <Box
        width={isMobile ? '130px' : 'small'}
        height={isMobile ? '130px' : 'small'}
        margin={{ vertical: 'small' }}
      >
        <ImgixImage
          src={imageUrl}
          srcSetOptions={{
            auto: ['compress', 'format'],
          }}
          sizes={`(max-width: ${theme.global?.breakpoints?.small?.value}px) 75vw, 400px`}
          fit="contain"
        />
      </Box>
      <Formik
        initialValues={{
          productId: extractGid(get('id', product)),
          variantId: get('id', variant),
          gallonCount: '',
          quantityMap: {},
          lineItems: [],
          primerQuantityMap: {},
          includePrimer: true,
        }}
        onSubmit={async (values) => {
          const toAdd = [
            ...keys(values.quantityMap),
            ...(values.includePrimer ? keys(values.primerQuantityMap) : []),
          ];
          const items = map(
            (x) => ({
              variantId: x,
              quantity: values.quantityMap[x] || values.primerQuantityMap[x],
            }),
            toAdd
          );

          const inventoryPolicies = toAdd.reduce((mem, id) => {
            mem[id] = allVariants.find(
              (x) => x.shopifyId === extractGid(id)
            )?.inventoryPolicy;
            return mem;
          }, {});

          await addToCart(items, {
            inventoryPolicies,
          });
          track(PAINT_ADDED, {
            location: window.location.pathname,
            productId: extractGid(values.productId),
            variantQuantities: mapKeys(extractGid, values.quantityMap),
          });
          if (isFunction(onAdded)) {
            onAdded();
          }
        }}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
          const selectedProduct = find((x) => {
            const productId = get('shopifyId', x) || get('id', x);
            return isEqual(values.productId, productId);
          }, altProducts);

          const selectedProductType = get('productType', selectedProduct);

          const halfGalVariant = find(
            isVariantHalfGal,
            selectedProduct.variants
          );

          const oneGalVariant = findOneGalVariant(selectedProduct.variants);

          const gallonOptions = [
            ...(halfGallonAvailable
              ? [
                  {
                    label: `Half Gallon – ${formatCurrency(
                      get('price', halfGalVariant)
                    )}`,
                    value: 0.5,
                  },
                ]
              : []),
            ...new Array(30).fill(1).map((x, i) => ({
              label:
                i === 0
                  ? `1 Gallon – ${formatCurrency(get('price', oneGalVariant))}`
                  : `${i + 1} Gallons`,
              value: i + 1,
            })),
          ];

          const handleVariantsChange = (quantityMap, variants) => {
            setFieldValue('quantityMap', quantityMap);
            const lineItems = map(
              (x) => ({
                ...find({ id: x }, variants),
                quantity: quantityMap[x],
                productType: selectedProductType,
              }),
              keys(quantityMap)
            );
            setFieldValue('lineItems', lineItems);
            scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
          };
          const handlePrimerChange = (x) => {
            setFieldValue('primerQuantityMap', x);
          };
          const noneAvailable = every(
            (x) => x === null || x === 0,
            _values(values.quantityMap)
          );
          const recommendsPrimer =
            (get('tags', selectedProduct) || []).indexOf(
              PRIMER_RECOMMENDATION_TAG
            ) > -1 || selectedProduct?.productType === CABINET_DOOR;

          return (
            <Form style={{ width: '100%' }}>
              <Box margin={{ vertical: 'small' }}>
                <Box
                  fill="horizontal"
                  border={{ side: 'bottom', size: 'small' }}
                >
                  <Field
                    name="productId"
                    component={InputSelectWithDescription}
                    size="large"
                    options={finishOptions}
                    disabled={disabledProductOptions}
                    plain
                  />
                </Box>
                <Box margin={{ top: 'medium' }} fill="horizontal">
                  <Stack anchor="right">
                    <SelectContainer isMobile={isMobile}>
                      <Field
                        as="select"
                        name="gallonCount"
                        style={{
                          cursor: 'pointer',
                          color: 'inherit',
                          background: 'transparent',
                        }}
                      >
                        <option value="" disabled>
                          Select Quantity
                        </option>
                        {gallonOptions.map((x) => (
                          <option key={x.value} value={parseFloat(x.value)}>
                            {x.label}
                          </option>
                        ))}
                      </Field>
                    </SelectContainer>
                    <FormDown size="20px" color="black" />
                  </Stack>
                </Box>
                {!values.gallonCount && (
                  <Box
                    margin={{ top: 'medium' }}
                    padding={{ vertical: 'small' }}
                    align="center"
                    justify="center"
                  >
                    <Text textAlign="center" color="dark-3" size="small">
                      If you know how much paint you need, select above. If
                      you&apos;d like help,{' '}
                      <Anchor
                        data-calculator-button
                        color="dark-3"
                        onClick={() => onCalc(values.productId)}
                      >
                        try our paint calculator.
                      </Anchor>
                    </Text>
                  </Box>
                )}
                {values.gallonCount && (
                  <Box
                    pad={{
                      top: 'medium',
                    }}
                  >
                    {!noneAvailable && (
                      <Box align="center" justify="center">
                        <Text color="dark-3">You need</Text>
                      </Box>
                    )}
                    <GallonProductBreakdown
                      gallons={values.gallonCount}
                      productId={values.productId}
                      onChange={handleVariantsChange}
                      onNotifyRestock={onNotifyRestock}
                    />
                  </Box>
                )}
              </Box>

              {values.gallonCount && recommendsPrimer && (
                <Box
                  border={{ side: 'top', color: 'black', size: '2px' }}
                  pad={{ vertical: 'medium', horizontal: 'small' }}
                  fill="horizontal"
                  align="center"
                >
                  <Box pad="small" direction="row" align="start" gap="0.3rem">
                    <InfoToolTip text="Use primer with light colors or when painting wood cabinets and doors for best results. You'll need approx 1 Gallon Primer for every 2 Gallons of paint.">
                      <Text textAlign="center" color="dark-3">
                        We recommend using Primer with this color
                      </Text>
                    </InfoToolTip>
                  </Box>
                  <Box fill="horizontal" className="breakdown-outer">
                    <GallonProductBreakdown
                      gallons={Math.ceil(values.gallonCount / 2)}
                      label="Primer"
                      productId={get('id', primer)}
                      onChange={handlePrimerChange}
                      onSelect={(x) => {
                        setFieldValue('includePrimer', x.value);
                      }}
                      isSelected={values.includePrimer}
                      onNotifyRestock={onNotifyRestock}
                    />
                  </Box>
                </Box>
              )}

              {values.gallonCount && (
                <Box fill="horizontal">
                  <Button
                    onClick={handleSubmit}
                    primary
                    type="submit"
                    label="Add to Cart"
                    reverse
                    icon={isSubmitting ? <Loading /> : undefined}
                    disabled={isSubmitting || noneAvailable}
                    style={{ padding: '1rem' }}
                  />
                </Box>
              )}
              <div ref={scrollRef} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormPaintVariantSelector.propTypes = {
  variant: PropTypes.object,
  variants: PropTypes.array,
  altProducts: PropTypes.array,
  imageUrl: PropTypes.string,
  primer: PropTypes.object,
  onCalc: PropTypes.func,
  halfGallonAvailable: PropTypes.bool,
  onAdded: PropTypes.func,
  onNotifyRestock: PropTypes.func.isRequired,
};

export default memo(FormPaintVariantSelector);
