import { find, reduce } from '../nodash';
import {
  isVariantHalfGal,
  isVariant5Gal,
  isVariantPurchasable,
  findOneGalVariant,
} from '../product';

const calculateVariantsForGallons = (variants, gallons) => {
  const halfGallon = find(isVariantHalfGal, variants);
  const oneGallon = findOneGalVariant(variants);
  const fiveGallon = find(isVariant5Gal, variants);
  const isHalfGallonCount = gallons === '0.5' || gallons === 0.5;

  const baseFiveGalCount = fiveGallon
    ? oneGallon
      ? Math.floor(gallons / 5)
      : Math.ceil(gallons / 5)
    : 0;

  const fiveGalCount = isVariantPurchasable(fiveGallon)
    ? isVariantPurchasable(oneGallon)
      ? Math.floor(gallons / 5)
      : Math.ceil(gallons / 5)
    : 0;

  const baseOneGalCount = isHalfGallonCount
    ? 0
    : gallons - fiveGalCount * 5 < 0
    ? 1
    : gallons - fiveGalCount * 5;

  const oneGalCount = isVariantPurchasable(oneGallon)
    ? isHalfGallonCount
      ? 0
      : gallons - fiveGalCount * 5
    : 0;

  const halfGalCount = isHalfGallonCount ? 1 : 0;

  const result = reduce(
    (mem, x) => {
      if (x.baseCount > 0 && x.variant) {
        if (x.outOfStock) {
          mem[x.variant.id] = null;
        } else {
          mem[x.variant.id] = x.count;
        }
      }
      return mem;
    },
    {},
    [
      {
        variant: fiveGallon,
        count: fiveGalCount,
        baseCount: baseFiveGalCount,
        outOfStock: fiveGallon && !isVariantPurchasable(fiveGallon),
      },
      {
        variant: oneGallon,
        count: oneGalCount,
        baseCount: baseOneGalCount,
        outOfStock: oneGallon && !isVariantPurchasable(oneGallon),
      },
      {
        variant: halfGallon,
        count: halfGalCount,
        baseCount: halfGalCount,
        outOfStock: halfGallon && !isVariantPurchasable(halfGallon),
      },
    ]
  );
  return result;
};

export default calculateVariantsForGallons;
