import React, { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'grommet';
import { isPaint, isWallcovering } from '../../lib/product';

import Modal from '../Modal';
import PaintVariantSelector from '../Paint/PaintVariantSelector';
import WallcoveringsVariantSelector from '../Wallcoverings/WallcoveringsVariantSelector';
import { deactivateProductVariantSelector } from '../../state/product/productSlice';
import { setProductId } from '../../state/calculator/calculatorSlice';

const ModalProductBuyOptions = () => {
  const dispatch = useDispatch();
  const {
    productType,
    variantId,
    altProducts,
    paintCollectionId,
    paintCollection,
    wallcoveringsCollectionId,
    wallcoveringsCollection,
    product,
  } = useSelector((state) => state.product.productForSelection);
  const handleClose = useCallback(() => {
    dispatch(deactivateProductVariantSelector());
  });
  const handleCalc = useCallback((productId) => {
    dispatch(setProductId(productId));
  });

  return (
    <Modal onClose={handleClose}>
      <Box width="medium" pad="large">
        {isPaint({ productType }) && (
          <PaintVariantSelector
            variantId={variantId}
            paintCollectionId={paintCollectionId}
            paintCollection={paintCollection}
            altProducts={altProducts}
            onCalc={handleCalc}
          />
        )}
        {isWallcovering({ productType }) && (
          <WallcoveringsVariantSelector
            variantId={variantId}
            wallcoveringsCollectionId={wallcoveringsCollectionId}
            wallcoveringsCollection={wallcoveringsCollection}
            altProducts={altProducts}
            onCalc={handleCalc}
            product={product}
          />
        )}
      </Box>
    </Modal>
  );
};

export default memo(ModalProductBuyOptions);
