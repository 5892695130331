import React, { useRef, useMemo, memo, useContext } from 'react';
import {
  get,
  map,
  find,
  filter,
  isNil,
  isFunction,
  values as _values,
  titleCase,
} from '../../lib/nodash';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  ThemeContext,
  ThemeType,
} from 'grommet';
import { FormDown } from 'grommet-icons';
import styled from 'styled-components';

import InputSelectWithDescription from '../Inputs/InputSelectWithDescription';
import Loading from '../Loading';
import useMobile from '../useMobile';
import useAddToCart from '../Cart/useAddToCart';
import extractGid from '../../lib/extractGid';
import formatCurrency from '../../lib/formatCurrency';
import {
  findFullWallcoveringsVariants,
  findPanelVariants,
  findSingleRollVariants,
  getWallcoveringsProductColorway,
  getWallcoveringsVariantName,
  isPeelAndStick,
  isTraditional,
} from '../../lib/product';
import { WALLCOVERINGS_ADDED } from '../../lib/analytics/segmentActions';
import { track } from '../../lib/analytics';
import ImgixImage from '../ImgixImage';

const SelectContainer = styled(Box)`
  font-size: ${(p) => (p.isMobile ? '1.4rem' : '1.6rem')};
  position: relative;
  button {
    border-bottom: 2px solid #000;
  }
  button[role='menuitem'] {
    padding: 0.3rem;
  }
  input {
    padding-left: 0;
    padding-right: 0;
  }
  select {
    text-transform: none;
    border: none;
    border-bottom: 2px solid #000;
    font-size: 23px;
    padding: 0.8rem 0.6rem;
    height: auto;
    line-height: 28px;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
  }
`;

const getDescriptionForWallcoveringVariant = (variant) => {
  if (isPeelAndStick(variant)) {
    return 'Removable';
  }
  if (isTraditional(variant)) {
    return 'Traditional';
  }
  return 'Traditional';
};

type Props = {
  variant: any;
  variants: any[];
  altProducts?: any[];
  collection: any;
  imageUrl: string;
  onCalc: (productId: string) => void;
  onAdded: () => void;
  onNotifyRestock: () => void;
};

const FormWallcoveringsVariantSelector = ({
  variant,
  variants,
  collection,
  imageUrl,
  onCalc,
  onAdded,
  onNotifyRestock,
}: Props) => {
  const theme = useContext<ThemeType>(ThemeContext);
  const { addToCart } = useAddToCart();
  const isMobile = useMobile();
  const scrollRef = useRef(null);
  const product = get('product', variant);

  const fullVariants = useMemo(
    () => findFullWallcoveringsVariants(variants),
    [variants]
  );

  const finishOptions = useMemo(
    () =>
      map((x) => {
        const id = get('shopifyId', x) ?? get('id', x);
        return {
          label: getWallcoveringsVariantName(x),
          value: id || null,
          description: getDescriptionForWallcoveringVariant(x),
        };
      }, fullVariants),
    [fullVariants, getDescriptionForWallcoveringVariant]
  );

  const disabledProductOptions = map(
    (z) => finishOptions.indexOf(z),
    filter((x) => {
      return isNil(get('value', x));
    }, finishOptions)
  );

  return (
    <Box align="center" className="form-container" justify="center">
      <Box justify="center" align="center">
        <Heading textAlign="center" level={4} margin={{ top: 'xsmall' }}>
          {collection?.title}
        </Heading>
        <Text textAlign="center" size="medium">
          {titleCase(getWallcoveringsProductColorway(collection, product))}
        </Text>
      </Box>
      <Box
        width={isMobile ? '130px' : 'small'}
        height={isMobile ? '130px' : 'small'}
        margin={{ vertical: 'small' }}
      >
        <ImgixImage
          src={imageUrl}
          srcSetOptions={{
            auto: ['compress', 'format'],
            cs: 'srgb',
          }}
          sizes={`(max-width: ${theme.global?.breakpoints?.small?.value}px) 75vw, 400px`}
          fit="contain"
        />
      </Box>
      <Formik
        initialValues={{
          variantId: get('id', variant),
          quantity: '1',
        }}
        onSubmit={async (values) => {
          const variant = find(
            (x) => x.shopifyId === values.variantId,
            variants
          );

          const inventoryPolicies = {
            [values.variantId]: variant?.inventoryPolicy,
          };

          await addToCart(
            [
              {
                variantId: values.variantId,
                quantity: parseInt(values.quantity),
              },
            ],
            {
              inventoryPolicies,
            }
          );
          track(WALLCOVERINGS_ADDED, {
            location: window.location.pathname,
            productId: extractGid(product.shopifyId ?? product.id),
            variantQuantities: {
              [values.variantId]: values.quantity,
            },
          });
          if (isFunction(onAdded)) {
            onAdded();
          }
        }}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
          const selectedVariant = find(
            (x) => x.shopifyId === values.variantId,
            variants
          );
          return (
            <Form style={{ width: '100%' }}>
              <Box margin={{ vertical: 'small' }}>
                <Box
                  fill="horizontal"
                  border={{ side: 'bottom', size: 'small' }}
                >
                  <Field
                    name="variantId"
                    component={InputSelectWithDescription}
                    size="large"
                    options={finishOptions}
                    disabled={disabledProductOptions}
                    plain
                  />
                </Box>
                <Box margin={{ top: 'medium' }} fill="horizontal">
                  <Stack anchor="right">
                    <SelectContainer isMobile={isMobile}>
                      <Field
                        as="select"
                        name="quantity"
                        style={{
                          cursor: 'pointer',
                          color: 'inherit',
                          background: 'transparent',
                        }}
                      >
                        <option value="" disabled>
                          Select Quantity
                        </option>
                        <option key={1} value={1}>
                          1 - {formatCurrency(selectedVariant?.price)}
                        </option>
                        {new Array(29).fill(0).map((_, i) => (
                          <option key={i} value={i + 2}>
                            {i + 2}
                          </option>
                        ))}
                      </Field>
                    </SelectContainer>
                    <FormDown size="20px" color="black" />
                  </Stack>
                </Box>
              </Box>

              <Box fill="horizontal">
                <Button
                  onClick={handleSubmit}
                  primary
                  type="submit"
                  label="Add to Cart"
                  reverse
                  icon={isSubmitting ? <Loading /> : undefined}
                  disabled={isSubmitting}
                  style={{ padding: '1rem' }}
                />
              </Box>

              <div ref={scrollRef} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormWallcoveringsVariantSelector.propTypes = {
  variant: PropTypes.object,
  variants: PropTypes.array,
  altProducts: PropTypes.array,
  imageUrl: PropTypes.string,
  onCalc: PropTypes.func,
  onAdded: PropTypes.func,
  onNotifyRestock: PropTypes.func.isRequired,
};

export default memo(FormWallcoveringsVariantSelector);
