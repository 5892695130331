import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';
import { isEqual } from '../../lib/nodash';

import { getVariantGallonCount } from '../../lib/product';

const VariantGallonsPerUnitText = ({ variant, ...rest }) => {
  const gallonsPerUnit = getVariantGallonCount(variant);
  const perUnitText = isEqual(0.5, gallonsPerUnit)
    ? 'Half'
    : isEqual(5, gallonsPerUnit)
    ? 'Five'
    : 'Single';

  const shortVariantName = `${perUnitText} Gallon`;
  return <Text {...rest}>{shortVariantName}</Text>;
};

VariantGallonsPerUnitText.propTypes = {
  variant: PropTypes.object,
};

export default VariantGallonsPerUnitText;
