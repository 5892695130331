import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Box } from 'grommet';
import { get } from '../../lib/nodash';

import FormWallcoveringsVariantSelector from './FormWallcoveringsVariantSelector';
import Loading from '../Loading';
import { shopifyClient as client } from '../../gatsby-theme-apollo/client';
import findImage from '../../lib/findImage';
import deNodify from '../../lib/deNodify';
import encodeGid from '../../lib/encodeGid';
import { deactivateProductVariantSelector } from '../../state/product/productSlice';
import { setActiveModal } from '../../state/ui/uiSlice';
import { GET_FULL_PRODUCT_BY_VARIANT_ID } from '../../queries/productQueries';
import {
  excludeVariants,
  getExcludedVariantsFromProductResponse,
} from '../../lib/product';

const WallcoveringsVariantSelector = ({
  variantId,
  onCalc,
  wallcoveringsCollection,
  product,
}) => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery(GET_FULL_PRODUCT_BY_VARIANT_ID, {
    variables: { variantId: encodeGid(variantId) },
    client,
  });

  const variants = excludeVariants(
    get('variants', product) ? deNodify(get('variants', product)) : [],
    getExcludedVariantsFromProductResponse(data)
  );

  const gridImageUrl = useMemo(
    () =>
      findImage(get('media', product) ?? [], '_grid') ||
      findImage(get('media', product) ?? [], '_square') ||
      findImage(get('media', product) ?? [], '_slide2'),
    [product]
  );

  const handleAdded = useCallback(
    () => dispatch(deactivateProductVariantSelector()),
    [dispatch, deactivateProductVariantSelector]
  );
  const handleNotifyRestock = () => {
    dispatch(deactivateProductVariantSelector());
    dispatch(
      setActiveModal({
        type: 'component',
        value: 'product-restock-notification',
        meta: {
          variantId,
          variantTitle: get('node.title', data),
          productTitle: get('title', product),
          isSample: false,
        },
      })
    );
  };

  return (
    <Box className="paint-variant-selector" gap="small">
      {loading ? (
        <Box fill justify="center" align="center">
          <Loading size="large" />
        </Box>
      ) : (
        <FormWallcoveringsVariantSelector
          variant={get('node', data)}
          variants={variants}
          collection={wallcoveringsCollection}
          imageUrl={gridImageUrl}
          onCalc={onCalc}
          onAdded={handleAdded}
          onNotifyRestock={handleNotifyRestock}
        />
      )}
    </Box>
  );
};

WallcoveringsVariantSelector.propTypes = {
  variantId: PropTypes.string.isRequired,
  altProducts: PropTypes.array,
  onCalc: PropTypes.func,
  handleGallonSelect: PropTypes.func,
  paintCollectionId: PropTypes.string,
  paintCollection: PropTypes.object,
};

export default memo(WallcoveringsVariantSelector);
